.product-image{
    height:100px;
    width:100px;
    object-fit: contain;
}

.primary-background{
    background-color: rgb(39, 68, 114) !important;
    color: white !important;
} 

.primary-border{
    border:1px solid  rgb(39, 68, 114) !important;
}

.primary-left-border{
    border-left:1px solid  rgb(39, 68, 114) !important;
}

.primary-color{
    color: rgb(39, 68, 114);  
}

.cursor-pointer{
    cursor: pointer;
}

.products-list-scroll{
    height:450px;
    overflow-y: scroll;
}

.products-list-height{
    height:600px;
}

.fs-14{
    font-size:14px !important;
}

@media (max-width:768px){
    .primary-left-border{
        border-left:none !important;
        border-top:1px solid  rgb(39, 68, 114) !important;
    }
}

.fs-15{
    font-size: 15px !important;
}

.fs-13{
    font-size: 13px !important;
}

.responsive-width {
    width: 100%; 
  }
  
  @media (min-width: 768px) { 
    .responsive-width {
      width: 30%;
      height: 50%;
    }
  }

  .invoice-list {
    padding: 0;
  }
  
  @media (max-width: 767px) {
    .invoice-list {
      padding-top: 30px; 
    }
  }

.dark-orange{
    color: #EBA55D !important;
}
.dark-blur-clr{
    color: #353431 !important;
}
.skyblue-bg-clr{
    background: linear-gradient(135deg, #5BC7D0, #8bd1d6);
}
.skyblue-clr{
  color: #5BC7D0 !important;
}
.dark-blue-bg{
    background: linear-gradient(to right, #5196FE, #1E6BFC);
}
.light-orange-bg{
    background-color: #F9DFC8 !important;
}
.responsive-width {
    width: 100%;
  }
  
  @media (min-width: 765px) {
    .responsive-width {
      width: 25%;
      height: 100%;
    }
  }
  
